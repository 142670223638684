<template>
    <v-container class="body-card">
        <v-row>
            <v-col cols="12" class="pb-0" v-if="detail.fare_type == '01'">
                <v-card  class="mx-auto my-3 border-green">
                    <v-row justify="center" class="my-3 mr-4">
                        <v-col cols="auto" class="text-center py-0 grey-text">
                            {{detail.route_name}}
                        </v-col>
                    </v-row>
                    <v-row class="mt-n2" justify="center">
                        <v-col cols="auto" class="text-center py-0 text-color-route">
                            <h2 class="text-bus">{{detail.orig_loc}}</h2>
                            <div class="caption font-weight-light mt-n2">{{detail.original_loc}}</div>
                        </v-col>
                        <v-col cols="auto" class="d-flex align-center pa-0">
                            <img 
                                width="30px"
                                :src="require('@/assets/home/bus.svg')" />
                        </v-col>
                        <v-col cols="auto" class="text-center py-0 text-color-route">
                            <h2 class="text-bus">{{detail.dest_loc}}</h2>
                            <div class="caption font-weight-light mt-n2">{{detail.destination_loc}}</div>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col class="my-0 py-0 text-center">
                            <div class="caption text-color-date font-weight-regular"><i>{{detail.date}}</i></div>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col class="my-0 py-2 text-center">
                            <div class="font-invoice">{{detail.ticket}}</div>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col v-if="detail.expired_note === 'ExpiredOnSameDay'" class="px-6 py-1 col col-12">
                            <p class="detail-route black--text mb-0 font-weight-black">
                            {{ $t("ExpiredOnSameDay") }}
                            </p>
                        </v-col>
                        <v-col
                            v-else-if="detail.expired_note === 'ExpiredAfterPurchase'"
                            class="px-6 py-1 col col-12"
                        >
                            <p class="detail-route black--text mb-0 font-weight-black">
                            {{ $t("ValidFor") }} {{ fare_rule.valid_days }} {{ $t("Days") }}
                            </p>
                        </v-col>
                        <v-col
                            v-else-if="detail.expired_note === 'ExpiredAfterUsage'"
                            class="px-6 py-1 col col-12"
                        >
                            <p class="detail-route black--text mb-0 font-weight-black">
                            {{ $t("ValidFor") }} {{ fare_rule.valid_days }} {{ $t("ExpiredAfterUsage") }}
                            </p>
                        </v-col>
                        <v-col
                            v-else-if="detail.expired_note === 'ValidationPeriod' && validate_end_date != ''"
                            class="px-6 py-1 col col-12"
                        >
                            <p class="detail-route black--text mb-0">
                            <span class="font-weight-black">{{ $t("ValidFor") }} </span><br/>
                            {{ " " + validate_start_date + " " }}{{ $t("to") + " " + validate_end_date + ", " }} 
                            {{ " " + fare_rule.validate_open_time + " " }}{{ $t("to") }}
                            {{ fare_rule.validate_closed_time + " " }}
                            </p>
                        </v-col>
                        <v-col
                            v-else-if="detail.expired_note === 'ValidationPeriod' && validate_end_date == ''"
                            class="px-6 py-1 col col-12"
                        >
                            <p class="detail-route black--text mb-0">
                            <span class="font-weight-black">{{$t("ValidFrom")}} </span><br/>
                            {{ " " + validate_start_date + ", " }}
                            {{ " " + fare_rule.validate_open_time + " " }}{{ $t("to") }}
                            {{ fare_rule.validate_closed_time + " " }}
                            </p>
                        </v-col>
                        <v-col v-else-if="detail.expired_note === 'Unlimited'" class="d-flex ma-0">
                            <p class="detail-route black--text mb-0 font-weight-black"></p>
                        </v-col>
                        <v-col v-else class="d-flex ma-0">
                            <p class="detail-route black--text mb-0 font-weight-black"></p>
                        </v-col>
                    </v-row>
                </v-card>
                <v-card class="mx-auto border-green">
                    <v-row align="center" class="my-1">
                        <v-col cols="12" class="px-6 py-1">
                            <p class="subtitle-1 font-weight-bold mb-n1">{{detail.route_name}}</p>
                            <span class="caption font-weight-bold">{{$t("Route")}} {{detail.original_loc}} {{$t("to")}} {{detail.destination_loc}} : </span>
                            <div class="text-justify caption line-height-normal mb-5">
                                {{detail.route_loc}}
                            </div>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
            <v-col cols="12" class="pb-0" v-if="detail.fare_type == '03'">
                <v-card class="rounded-card border-green">
                    <v-container>
                        <!-- <p class="header-title mb-0" align="center"> {{$t("MultidaysInformation")}} </p> -->
                        <v-row align="center" class="ma-0 pa-4 pb-0 pt-0">
                            <v-col class="ma-0 px-0 py-5 d-flex text-center justify-center" cols="12">
                                <h2 class="pl-2 ticket">
                                    {{$t("EvoucherTicket")}}
                                </h2>
                            </v-col>
                        </v-row>
                        <v-row style="border-top: 1px solid #cecece;">
                            <v-col class="px-5 pb-0">
                                <b style="font-size: 16px;color: rgba(0, 0, 0, 0.8);">{{$t("TripInformation")}}</b><br/>
                                {{$t("Price")}} :  {{Intl.NumberFormat().format(detail.price_per_ticket)}} {{$t("Vnd")}}/{{$t("Turn")}}<br/>
                                {{$t("ValidUntil")}} : {{validate_end_date == "" ? "-" : validate_end_date}}
                                
                                <!-- <p class="mb-0"> {{$t("Maximum")}} {{detail.max_trip}} {{$t("Trip")}} - {{detail.max_trip_perday}} {{$t("TripsPerYear")}}</p> -->
                                <!-- <p class="mb-0">Multi day ticket is valid for 30 days</p> -->
                            </v-col>
                        </v-row>
                        <!-- <v-row>
                            <v-col v-if="detail.expired_note === 'ExpiredOnSameDay'" class="d-flex ma-0 px-5">
                                <p class="detail-route black--text mb-0 font-weight-black"> {{ $t("ExpiredOnSameDay") }} </p>
                            </v-col>
                            <v-col v-else-if="detail.expired_note === 'ExpiredAfterPurchase'"
                                class="d-flex ma-0 px-5" >
                                <p class="detail-route black--text mb-0 font-weight-black">
                                    {{ $t("ValidFor") }} {{ detail.fare_rule.valid_days }} {{ $t("Days") }}
                                </p>
                            </v-col>
                            <v-col v-else-if="detail.expired_note === 'ValidationPeriod' && validate_end_date != ''"
                                class="d-flex ma-0 px-5" >
                                <p class="detail-route black--text mb-0">
                                <span class="">{{ $t("ValidFor") }} </span> <br/>
                                    {{ " " + validate_start_date + " " }}{{ $t("to") + " " + validate_end_date + ", " }} 
                                    {{ " " + validate_open_time + " " }}{{ $t("to") }}
                                    {{ validate_closed_time + " " }}
                                </p>
                            </v-col>
                            <v-col v-else-if="detail.expired_note === 'ValidationPeriod' && validate_end_date == ''"
                                class="d-flex ma-0 px-5">
                                <p class="detail-route black--text mb-0">
                                    <span class="">{{ $t("ValidFrom") }} </span> <br/>
                                    {{ " " + validate_start_date}},
                                    {{ " " + validate_open_time + " " }}{{ $t("to") }}
                                    {{ validate_closed_time + " " }}
                                </p>
                            </v-col>
                            <v-col v-else-if="detail.expired_note === 'ExpiredAfterUsage'"
                                class="d-flex ma-0 px-5" >
                                <p class="detail-route black--text mb-0 font-weight-black">
                                {{ $t("ValidFor") }} {{ " " + fare_rule.expire_after_usage + " " }} {{$t("ExpiredAfterUsage")}}
                                </p>
                            </v-col>
                            <v-col v-else-if="detail.expired_note === 'Unlimited'" class="d-flex ma-0 px-5">
                                <p class="detail-route black--text mb-0 font-weight-black"></p>
                            </v-col>
                            <v-col v-else class="d-flex ma-0 px-5">
                                <p class="detail-route black--text mb-0 font-weight-black"></p>
                            </v-col>
                        </v-row> -->
                        <v-row>
                            <v-col class="my-0 pl-5">
                                <div class="text-color-date">{{$t("TicketAvailable")}}: {{parseInt(detail.max_trip) - detail.used_ticket}} {{$t("Times")}}</div>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-col>
        </v-row>
  </v-container>
</template>

<script>
import moment from "moment"
export default {
    name:"TripDetail",
    data(){
        return {
            detail : "",
            fare_rule : "",
            validate_start_date : "",
            validate_end_date:"",
            validate_closed_time:"",
            validate_open_time:"",
            expire_after_usage:"",
        }
    },
    mounted(){
        if(this.$route.params.data == undefined){
            this.$router.go(-1)
        }
        this.detail = this.$route.params.data
        this.fare_rule = this.detail.fare_rule
        if(this.fare_rule.validate_start_date != undefined){
            this.validate_start_date = moment.utc(this.fare_rule.validate_start_date).format("DD-MM-YYYY")
        } else {
            this.validate_start_date = ""
        }
        if(this.fare_rule.validate_end_date != undefined){
            if(this.fare_rule.validate_end_date != ''){
            this.validate_end_date = moment.utc(this.fare_rule.validate_end_date).format("DD-MM-YYYY")
            } else {
            this.validate_end_date = ""
            }
        } else {
            this.validate_end_date = ""
        }
        if(this.fare_rule.validate_closed_time != undefined){
            this.validate_closed_time = this.fare_rule.validate_closed_time
        } else {
            this.validate_closed_time = ""
        }
        if(this.fare_rule.validate_open_time != undefined){
            this.validate_open_time = this.fare_rule.validate_open_time
        } else {
            this.validate_open_time = ""
        }
        if(this.fare_rule.expire_after_usage != undefined){
            this.expire_after_usage = this.fare_rule.expire_after_usage
        } else {
            this.expire_after_usage = ""
        }
        if(this.validate_end_date == ""){
            this.validate_end_date = moment(moment().format("YYYY")+'-12-01').endOf('month').format('DD-MM-YYYY');
        }
        
    }
    
}
</script>

<style lang="scss" scoped>
.body-card {
  background-color: #f5fdf7;
  height: 100%;
  width: 100%;
  padding-top: 0;
}
.header-title {
  font-family: Roboto;
  font-style: normal;
  font-size: 20px;
  line-height: 23px;
  letter-spacing: -0.02em;

  color: #4bb14e;
}
.white-background {
  background: #fff;
}
.border-green {
    border: 1px solid #4BB14E;
}
.line-height-normal {
    line-height: normal;
}
.text-color-route {
    color: #45a648;
}
.text-color-date {
    color: #489E4B;
}
.font-invoice {
    font-size: 0.6rem!important;
}
.grey-text {
  color: #535353;
}
.text-bus {
    font-size: 26px;
    font-weight: 400;
}
.rounded-card {
  border-radius: 15px;
}
.header-title {
  font-family: Roboto;
  font-style: normal;
  font-size: 20px;
  line-height: 23px;
  letter-spacing: -0.02em;

  color: #4bb14e;
}
.route-title {
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  margin-bottom: -5px;

  color: #000000;
}
.detail-title {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
  display: flex;
  align-items: center;
  margin-top: -40px;
  margin-bottom: -20px;
  color: #000000;
}

.ticket {
  font-weight: bold;
  font-size: 34px;
  line-height: 118.88%;

  color: #489E4B;
}


.name-loc {
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 118.88%;
  /* identical to box height, or 31px */

  text-transform: capitalize;

  color: #489e4b;
}

.name-location {
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 12px;
  /* identical to box height */

  text-transform: capitalize;

  color: #4bb14e;
}

.detail-route {
  margin-top: -10px;
  font-size: 14px;
  color: #4bb14e;
  text-align: justify;
}
</style>